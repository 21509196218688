<template>
  <div>
<!--    <el-alert
        title="贵公司相关站点将于2024-05-31停止服务，请尽快与我们联系，以免影响网站的正常访问"
        type="warning"
        :closable="false">
    </el-alert>-->

    <el-drawer
        title="通知书"
        :size="size"
        :close-on-press-escape="false"
        :visible.sync="drawer"
        direction="rtl"
        :before-close="handleClose">
      <div>
        <el-result
            icon="warning"
            title="催款通知书"
            subTitle="由于长达1年多未收到贵公司剩余的相关开发费用，且长期沟通未果，现对贵公司网站发布相关通知，请尽快与我们取得联系，以免影响正常使用 (届时数据将不做保留)">
          <template slot="extra">
            <div style="width: 100%">
              <el-image
                  style="width: 50%; height: auto"
                  :src="wxUrl"
                  fit="fill"></el-image>
            </div>

            <el-button icon="el-icon-phone-outline" type="primary" size="medium">199-2833-2465</el-button>
          </template>
        </el-result>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {getNowDate, compareDates, isMobile} from '@/utils/index'

export default {
  data() {
    return {
      wxUrl: require('@/assets/images/cost_wx.png'),
      size: '30%',
      drawer: false
    }
  },
  created() {
    const lastDay = new Date("2025-2-1")
    const toDay = new Date(getNowDate());
    const close = compareDates(lastDay, toDay)
    const mobile = isMobile()

    if(mobile) {
      this.size = '100%'
    } else {
      this.size = '30%'
    }


    if(close === -1) {
      console.log('compareDates2', close)
      this.drawer = true
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('暂不支持关闭（请联系我们）').then(_ => {

      }).catch(_ => {

      });
    }
  }
}
</script>